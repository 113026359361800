:root {
  
    --bleupastel:  #dbfcfb;
    --rosepastel :#f9d7d7;
    --bleuciel:  #92eceb;
    --rose :  #f49090; 
    --bleu :  #bbf3f7;
    --bleuclair :#93f7e1;
    --marronclair :#cfc961;
    --vertpastel :#a0f950;
    --jaunepastel :#ffedB3;
    --vert : #0ee732;
    --jaune:#e2de08;
    --rouge :#f06210;
    
  }
  

.container {
    max-height: 90vh;
  
}
 
.containerChoixclient {
    max-height: 90vh;
    background-color: var(--vertpastel);
}

.outlined {
    display : flex;
    border-style:inset;
    border-width: 2px;
    border-radius: 4px;
}


.outlined_encours {
    display : flex;
    border-style:outset;
    border-width: 1px;
    border-radius: 4px;
    margin-top:10px;
    margin-bottom:15px;
   
}

.eselectionEntreprise:hover {
    cursor: pointer;        
}


.contenuToolbar {
    display: flex;
    justify-content: flex-end;
   
}


.carte {
    max-width: 20em;
}

.tablerowclient {
    background-color: var(--vertpastel);
}
.tablebodyclient {
    background-color: var(--rosepastel);
}

.textFieldChoix {
    background-color: var(--rosepastel);
    height :20px;
    width : 50px;
}
.zresizeButton {
    margin-right:10px; 
}
