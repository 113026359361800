.Datepick {
    font-family: sans-serif;
    font-weight:bold;
    margin-right:2px ;
    margin-left:2px ;
  }
  
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: line;
    width: 100px;
    height:20px;
  }
  