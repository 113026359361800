:root {
  
  --bleupastel:  #dbfcfb;
  --rosepastel :#f9d7d7;
  --bleuciel:  #92eceb;
  --rose :  #f49090; 
  --bleu :  #bbf3f7;
  --bleuclair :#93f7e1;
  --marronclair :#cfc961;
  --vertpastel :#a0f950;
  --vertclair : #bdf090;
  --jaunepastel :#ffedB3;
  --vert : #0ee732;
  --jaune:#e2de08;
  --rouge :#f06210;
  
}





.Resizer {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 15px;
  margin: -5px 0;
  border-top: 5px solid rgba(206, 120, 120, 0.726);
  border-bottom: 5px solid rgba(206, 120, 120, 0.726);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 15px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.mainbox {
  display: flex;
   flex-direction: row;
   justify-content: left;
}
.boxTop {

  background-color:  var(--vert);
  color :"primary.contrastText";
   height :100%;
   width :100%;
}
.boxLeft {
  
  background-color: var(--bleuciel);
  color :"primary.contrastText";
   height: 100%;
   width :100%;
}
.boxInterv {
  background-color:   var(--jaunepastel);
  color :"primary.contrastText";
   height :100%;
   width :100%;
}
.boxAppel {
  background-color:   var(--vertclair);
  color :"primary.contrastText";
   height :100%;
   width :100%;
}
.boxMaint {
  background-color:   var(--rosepastel);
  color :"primary.contrastText";
   height :100%;
   width :100%;
}
.boxCenter {
 
  background-color:   var(--rosepastel);
  color :"primary.contrastText";
   height :100%;
}
.boxRight {
 
  background-color:  var(--bleuciel);
  color :"primary.contrastText";
   height :100%;
}

.boxBottom {
 
  background-color:   var(--vert);
  color :"primary.contrastText";
   height :200%;
   width :100%;
  
}
.contCenter {
 
  background-color:   var(--rouge);
  color :"primary.contrastText";
   height :120%;
}

.fields {
  height: 800px;
  width: 300px;
  padding: 10px;
  overflow-x: auto;
  margin-bottom: 15px;
}
.field {
  margin-bottom: 15px;
}
.field label {
  display: block;
  font-weight: bold;
}
.field input {
  display: block;
  padding: 5px;
  font-size: 16px;
  box-sizing: border-box;
  width: 100%;
}
.field span {
  color: red;
  font-size: 11px;
}

