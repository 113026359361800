
   .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    margin-left:5px ;
    width: 200px;
    background-color: rgb(255, 255, 255);
    height:24px;
    font-weight: bold;
  }
  