:root {
  
  --bleupastel:  #dbfcfb;
  --rosepastel :#f9d7d7;
  
}
.App {
  text-align: left;
  background-color :var(--bleupastel);
  /*:#06756a; /* rgb(249, 215, 215);rosepastel '#F9D7D7'*/
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.CentreApplication {
  display: flex;
  flex-direction: row;
  justify-content: left;
  background-color: var(--bleupastel);
}
 
.MenuModules {
  display: flex;
  flex-direction: column;
  background-color: rgb(170, 170, 199);
  margin: 0;
  height: 91vh;
  min-width: 5vw;
  max-width: 20vw;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  
}
.App-header1 {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
