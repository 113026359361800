:root {
  
  --bleupastel:  #dbfcfb;
  --rosepastel :#f9d7d7;
  --rouge :#f06210;
  --vert : #0ee732;
  --vertpastel :#a0f950;
  --jaune:#e2de08;
  --gris: #e8e5ecc5;
}
  
  .tab-panel__header {
    font-family: 'Trebuchet MS';
    font-size: 16px;
    font-weight: bold;
    position: relative;
    top: 1px;
    line-height: 16px;
    
  }
    
  .tab-panel__content {
    background-color: var(--bleupastel);
    border: 1px solid #9099A2;
    height: 250px;
  } 
  
  .tab {
    background-color:#f9d7d7; 
    border: 1px solid #9099A2;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #313030;
    cursor: pointer;
    display: inline-block;
    padding: 6px 24px;
    position: relative;
    text-transform: capitalize;
    z-index: 0;
  }
  
  .tab:nth-child(n+2) {
      margin-left: -8px;
  }
  
  .tab:hover {
    color: #47aa05e8;
  }
  
  .tab--selected {
    background-color: #3a5887 ;   /*  #0f0808;*/
    border-bottom: 1px solid #D5D5D5;
    color: #edf0f8e3;
    font-size: 16px;
    font-weight: bold;
    padding-top: 6px;
    z-index: 1;
  }


  .tab-panel_interv__header {
    font-family: 'Trebuchet MS';
    font-size: 20px;
    font-weight: bold;
    position: relative;
    top: 1px;
    line-height: 24px;
    
  }

  .tab-panel_interv__content {
    background-color: var(--bleupastel);
    border: 1px solid #9099A2;
    height: 300px;
  } 
  
  .tab_interv {
    background-color:#5bee6e; 
    border: 1px solid #D5D5D5;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: #313030;
    cursor: pointer;
    display: inline-block;
    padding: 6px 24px;
    position: relative;
    text-transform: capitalize;
    z-index: 0;
  }
  
  .tab_interv:nth-child(n+2) {
      margin-left: -8px;
  }
  
  .tab_interv:hover {
    color: #78be4ae8;
  }
  
  .tab_interv--selected {
    background-color: #313030;   /*  #0f0808;*/
    border-bottom: 1px solid #D5D5D5;
    color: #edf0f8e3;
    font-size: 20px;
    font-weight: bold;
    padding-top: 6px;
    z-index: 1;
  }

  .form {
    margin:10px; 
    padding: 5px 5px;
    padding-top: 6px;
    background: #F5F5F5;  
  }
  .label {
    font-family: sans-serif;
    font-weight:bold;
    margin-right:2px ;
    margin:5px 0;
    padding-top: 2px;
  }
  .label-red {
    font-family: sans-serif;
    font-weight:bold;
    color:#ff0000;
    margin-right:2px ;
    margin:5px 0;
    padding-top: 2px;
  }
 
  .label-hidden {
    font-family: sans-serif;
    font-weight:bold;
    color:#ffffff;
    visibility:hidden;  
    margin-right:2px ;
    margin:5px 0;
    padding-top: 2px;
  }
  .margin-l-5 {
    margin-left:5px ;   
  }
  .margin-l-10 {
    margin-left:10px ;   
  }
  .margin-r-50 {
    margin-right:50px ;   
  }
  .margin-t-15 {
    margin-left:15px ;
    margin-top:15px ;
  
  }
  .margin-t-10 {
    margin-top:12px ;

  }
  .input {
    padding-top:2px;
    border:1px solid #eee;
    font: normal 1em Verdana, sans-serif;
    color: #000000;
    margin-left:5px ;
    margin-right:20px ;
    visibility:visible;
  }
  .input-hidden {
    padding-top:2px;
    border:1px solid #eee;
    font: normal 1em Verdana, sans-serif;
    color: #000000;
    margin-left:5px ;
    margin-right:20px ;
    visibility:hidden;
  }
  .formLabel {
    font-size: 14px;
    margin-bottom: 15px;
    padding: 1px;
    flex-direction:"row";
    line-height: 24px;
  }
  
  .inputinterv {
    padding-top:2px;
    border:1px solid #eee; 
    margin-left:5px ;
    margin-right:20px ;
    visibility:visible;
  }
  .inputchoix {
    background-color: var(--vertpastel);
    color: #000000;
    visibility:visible;
  }
  .width20 {
    width :20px;
  }
  
  .width30 {
    width :30px;
  }
  .width50 {
    width :50px;

  }
  .width60 {
    width :60px;

  }
  .width70 {
    width :70px;

  }
  .width100 {
    width :100px;

  }
  .width130 {
    width :130px;

  }
  .width200 {
    width :200px;

  }
  .width300 {
    width :300px;

  }
 .gras {
  font-weight: bold;
 }  
  .LabelCheckBox {
    color: black;
    font-weight : 1000;
  }
  .LabelCheckBox:disabled {
    color: white;
    font-weight : 1000;
  }
       